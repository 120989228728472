var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":_vm.$t('Cancel'),"loading-text":_vm.$t('Updating balance...'),"submit-title":_vm.$t('Correct top up for {0}', [_vm.user.email]),"submit-success-message":_vm.$t('Credit removed from balance successfully!'),"submit-btn-text":_vm.$t('Remove credit'),"submit-promise-callback":_vm.revertTopUp,"fields":[{
        label: _vm.$t('Amount'),
        value: 'amount',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    },
    {
        label: _vm.$t('Channel'),
        value: 'type',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    }]},scopedSlots:_vm._u([{key:"field.amount",fn:function(ref){
    var errors = ref.errors;
    var label = ref.label;
    var classes = ref.classes;
return [_c('price-input',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors},model:{value:(_vm.obj.amount),callback:function ($$v) {_vm.$set(_vm.obj, "amount", $$v)},expression:"obj.amount"}})]}},{key:"field.type",fn:function(ref){
    var errors = ref.errors;
    var label = ref.label;
    var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.revertTypes},model:{value:(_vm.obj.type),callback:function ($$v) {_vm.$set(_vm.obj, "type", $$v)},expression:"obj.type"}})]}}],null,false,3764062901),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }