<template>
  <div id="devices-transaction">
    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        {{ this.$t('Devices Transactions') }}
      </v-card-title>
      <v-card-text>
        <ft-month-select
            :label="$t('Pick a month to load transactions').toString()"
            @month-selected="monthSelected"
        />

        <v-data-table
            hide-default-footer
            :items="devices"
            :headers="headers"
            item-key="item.id"
        >
          <template v-slot:item.lastUpdate="{ value }">
            {{ formatDateTime(value, $t('No update in selected period')) }}
          </template>
          <template v-slot:item.purchases="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:item.normalPurchases="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:item.topUps="{ value }">
            {{ formatMoney(value) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FtMonthSelect from '@/components/UIAccessories/FtMonthSelect.vue'

export default {
  name: 'DevicesTransactions',
  components: { FtMonthSelect },
  data () {
    return {
      devices: [],
      monthMenu: false,
      date: ''
    }
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('Device name') },
        { value: 'serialNumber', text: this.$t('Serial number') },
        { value: 'lastUpdate', text: this.$t('Last update') },
        { value: 'purchases', text: this.$t('Digital purchases') },
        { value: 'normalPurchases', text: this.$t('Legacy purchases') },
        { value: 'topUps', text: this.$t('Top ups') }
      ]
    }
  },
  methods: {
    async getDevicesTransactions (year, month) {
      const response = await this.$http.device.get(`queries/transactions/${year}/${month}`)
      this.devices = response.data
    },
    async monthSelected ({ year, month }) {
      await this.getDevicesTransactions(year, month)
    }
  }
}
</script>

<style scoped>

</style>
