<template>
  <action-modal
      v-model="fieldValue"
      :data="location"
      :edit-mode="editMode"
      :submit-btn-text="$t('Create')"
      :edit-btn-text="$t('Edit')"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"

      :submit-title="$t('Create location')"
      :edit-title="$t('Edit location')"
      :submit-success-message="$t('Location created successfully!')"
      :edit-success-message="$t('Location edited successfully!')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createLocationCallback"
      :edit-promise-callback="editLocationCallback"
      @close="close"
  />
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'LocationModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    location: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    },
    partnerId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'name', label: this.$t('Name'), rules: 'required' },
        { value: 'city', label: this.$t('City'), rules: '' },
        { value: 'address', label: this.$t('Address'), rules: '' },
        { value: 'civicNumber', label: this.$t('Civic number'), rules: '' }
      ]
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createLocationCallback () {
      return this.$http.device.post(
        '/locations/' + this.partnerId,
        this.location
      )
    },
    editLocationCallback () {
      return this.$http.device.put(
        '/locations/' + this.location.id,
        this.location
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
