<script>
export default {
  name: "ClientCustomerCompleteReg",
  data () {
    return {
      loading: Boolean(this.$route.query.loading) || false
    }
  },
  mounted () {
    if (this.loading) {
      setTimeout(this.reloadRegStatus, 800)
    }
  },
  methods: {
    async openStripeCheckout () {
      try {
        this.loading = true
        const res = await this.$http.payment.post('/client-customers/stripe-payment-methods', {
          returnUrl: location.href + '?loading=true'
        })

        window.location = res.data;
      } catch (e) {
        this.loading = false
      }
    },
    async reloadRegStatus () {
      await this.$store.dispatch('updateRegComplete')
      if (this.$store.getters.ccRegComplete) {
        await this.$router.push('/')
      } else {
        // fixme: logger saving to api -> should not arrive here
      }
    }
  }
}
</script>

<template>
  <div>
    <h1>{{ $t('Last step: add your IBAN for monthly payments') }}</h1>

    <v-alert color="secondary" class="lighten-4 mt-4 mb-6">
      <h4>{{ $t('Why should I add my IBAN?') }}</h4>
      {{ $t('The linked bank account will be used') }}
      {{ $t('The account that will be connected to will be used to make the overall payment at the end of the month for all top up transactions on virtual keys. The charge will be completely automatic using the SEPA Direct method.') }}
      <br>
      {{ $t('By accessing with the credentials you inserted in the previous step, you will be able to access this dashboard to top up, monitor paid transactions and modify the IBAN used even in a later occasion.') }}
    </v-alert>

    <div class="text-center">
      <v-btn @click="openStripeCheckout" color="primary" outlined :loading="loading">{{ $t('Add IBAN') }}</v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>