<template>
  <action-modal
      v-if="show"
      v-model="show"
      :data="obj"
      cancel-btn-text="Cancel"
      loading-text="Updating balance..."
      :submit-title="`Charge ${user.surname} ${user.name}'s Virtual Key`"
      submit-success-message="Virtual key charge successful!"
      submit-btn-text="Purchase"
      :submit-promise-callback="chargeVK"
      :fields="[{
          label: 'Amount (in cents)',
          value: 'amount',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      },
      {
          label: 'Serial number',
          value: 'serialNumber',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      }]"
  >
    <template #field.serialNumber="{ errors, label, classes }">
      <v-autocomplete
          :label="label"
          outlined
          :class="classes"
          v-model="obj.serialNumber"
          :items="devices"
          :error-messages="errors"
      />
    </template>
  </action-modal>
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'ChargeVirtualKeyModal',
  components: { ActionModal },
  props: {
    ftClientId: String,
    user: Object
  },
  data () {
    return {
      devices: [],
      obj: {
        amount: null,
        serialNumber: null
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    }
  },
  watch: {
    ftClientId (newVal) {
      if (newVal) this.reloadDevices()
    }
  },
  mounted () {
    if (this.ftClientId) this.getDevices()
  },
  methods: {
    reloadDevices () {
      this.obj.amount = null
      this.obj.serialNumber = null
      this.getDevices()
    },
    chargeVK () {
      return this.$http.payment.post(`virtual-keys/admin/${this.user.id}/charge`, {
        serialNumber: this.obj.serialNumber,
        amount: this.obj.amount,
      })
    },
    async getDevices () {
      try {
        const res = await this.$http.device(`devices/ft-client/${this.ftClientId}/sn-name`)
        this.devices = res.data.map(i => ({
          text: i.serialNumber + ' - ' + i.name,
          value: i.serialNumber,
          disabled: false
        }))
      } catch (e) {
      }
    }
  }
}
</script>
