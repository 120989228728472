var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Utenti organizzazione")]),(_vm.tokens && _vm.tokens.length)?_c('v-card',{staticClass:"my-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Active registration links')))]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.tokens,"headers":_vm.tokenHeaders,"item-key":"item.id","no-data-text":_vm.$t("There's no user yet. Add the first one!")},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":"","color":value.color,"small":""}},[_vm._v(_vm._s(value.label))])]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.revokeToken(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}],null,false,3373031384)})],1)],1):_vm._e(),_c('v-card',{staticClass:"my-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Utenti che hanno accesso alla dashboard")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mx-5"},[_c('v-text-field',{attrs:{"append-icon":"fa-search","outlined":"","label":_vm.$t('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-6",attrs:{"color":"green","dark":""},domProps:{"textContent":_vm._s('Invita')},on:{"click":function($event){return _vm.openInviteModal()}}})],1)]),_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"search":_vm.search,"item-key":"item.id","no-data-text":_vm.$t("There's no user yet. Add the first one!")},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":"","color":value.color,"small":""}},[_vm._v(_vm._s(value.label))])]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.removeUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-angle-double-down")])],1)]}}])})],1),_c('invite-user-to-platform-modal',{attrs:{"default-role":_vm.inviteModal.defaultRole},on:{"close":_vm.reload},model:{value:(_vm.inviteModal.show),callback:function ($$v) {_vm.$set(_vm.inviteModal, "show", $$v)},expression:"inviteModal.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }