<template>
  <action-modal
      v-model="fieldValue"
      :data="data"
      :edit-mode="editMode"
      :submit-btn-text="$t('Create')"
      :edit-btn-text="$t('Edit')"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"

      :submit-title="$t('Create user group')"
      :edit-title="$t('Edit user group')"
      :submit-success-message="$t('User group created successfully!')"
      :edit-success-message="$t('User group edited successfully!')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createLocationCallback"
      :edit-promise-callback="editLocationCallback"
      @close="close"
  />
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'UserGroupModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    data: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'name', label: this.$t('Name'), rules: 'required' },
        { value: 'notes', label: this.$t('Additional notes') + this.$t(' (optional)'), rules: '' },
      ]
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createLocationCallback () {
      return this.$http.auth.post(
        '/user-groups',
        this.data
      )
    },
    editLocationCallback () {
      return this.$http.auth.put(
        '/user-groups/' + this.data.id,
        this.data
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
