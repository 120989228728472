<template>
  <action-modal
      v-model="modalVisible"
      :data="data"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"
      submit-title="Upload new firmware for FoodyTek-PK"
      submit-success-message="Firmware uploaded successfully"
      submit-btn-text="Upload"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="submit"
      @close="closeAssignDevice"
  >
    <template #field.firmwareFile="{ errors, label, classes }">
      <v-file-input
          outlined
          v-model="data.firmwareFile"
          :label="label"
          :error-messages="errors"
          :class="classes"
          accept=".bin"
      />
    </template>
  </action-modal>
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'UploadFirmwareModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      data: {
        version: null,
        firmwareFile: null
      },
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12
        // md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'version', label: 'Version', rules: 'required' },
        { value: 'firmwareFile', label: 'Firmware file', rules: 'required' }
        // { value: 'phoneNumber', label: this.$t('Phone number'), rules: '' },
      ]
    },
    modalVisible: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    async submit () {
      const form = new FormData()
      form.set('file', this.data.firmwareFile)
      await this.$http.device.post('firmwares/foodytek-pk/' + this.data.version, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    closeAssignDevice () {
      this.$emit('close')
    }
  }
}
</script>
