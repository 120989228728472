<template>
  <action-modal
      v-model="fieldValue"
      :data="partner"
      :edit-mode="editMode"
      :submit-btn-text="$t('Create')"
      :edit-btn-text="$t('Edit')"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"

      :submit-title="$t('Create partner')"
      :edit-title="$t('Edit partner')"
      :submit-success-message="$t('Partner created successfully!')"
      :edit-success-message="$t('Partner edited successfully!')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createCallback"
      :edit-promise-callback="editCallback"
      @close="close"
  />
</template>

<script>

import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'PartnerModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    partner: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'name', label: this.$t('Name'), rules: 'required' },
        { value: 'businessName', label: this.$t('Business name'), rules: '' },
        { value: 'phoneNumber', label: this.$t('Phone number'), rules: '' },
        { value: 'city', label: this.$t('City'), rules: '' },
        { value: 'address', label: this.$t('Address'), rules: '' },
        { value: 'civicNumber', label: this.$t('Civic number'), rules: '' }
      ]
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createCallback () {
      return this.$http.device.post(
        '/partners',
        this.partner
      )
    },
    editCallback () {
      return this.$http.device.put(
        '/partners/' + this.partner.id,
        this.partner
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
