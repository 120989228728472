<template>
  <div>
    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%" v-if="tokens && tokens.length">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        {{ $t('Active registration links') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
            :items="tokens"
            :headers="tokenHeaders"
            item-key="item.id"
            :no-data-text="$t(`There's no user yet. Add the first one!`)"
        >
          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="revokeToken(item)">
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        Clienti
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center mb-4 mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('Search')"
              single-line
              hide-details/>
          <v-btn
              class="ml-6"
              color="green" dark
              v-text="$t('Create')"
              @click="registrationModal = true"/>
        </div>
        <v-data-table
            :items="users"
            :headers="headers"
            :search="search"
            item-key="item.id"
            :no-data-text="$t(`There's no user yet. Add the first one!`)"
        >
<!--          <template v-slot:item.crud="{ item }">-->
<!--            <v-btn color="red"-->
<!--                   class="pa-5"-->
<!--                   small icon-->
<!--                   @click="removeUser(item)">-->
<!--              <v-icon small>fa-trash</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
        </v-data-table>
      </v-card-text>
    </v-card>

    <invite-user-to-platform-modal
        v-model="registrationModal"
        specific-role="client-customer-admin"
        @close="reload"
    />
  </div>
</template>

<script>
import InviteUserToPlatformModal from "@/components/modals/commons/InviteUserToPlatformModal.vue";

export default {
  name: 'ClientCustomers',
  components: { InviteUserToPlatformModal },
  data () {
    return {
      users: [],
      tokens: [],
      search: '',
      registrationModal: false
    }
  },
  computed: {
    tokenHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    headers () {
      return [
        { text: 'Ragione sociale', value: 'businessName' },
        { text: 'Email', value: 'user.email' },
        { text: 'Nome', value: 'user.name' },
        { text: 'Cognome', value: 'user.surname' },
        // { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getRegistrationTokens()
      this.getUsers()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getUsers () {
      try {
        const response = await this.$http.auth.get('client-customers')
        this.users = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('invitations/ft-client/customers')
        this.tokens = response.data
      } catch (e) {
      }
    },

    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('Do you really want to revoke the registration token sent to the email <b>{email}</b>?', { email: item.email }), this.$t('Revoke'))
        await this.$http.auth.delete('commands/registration-tokens/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>
