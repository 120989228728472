var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":_vm.$t('Cancel'),"loading-text":_vm.$t('Topping up...'),"submit-title":_vm.$t('Top up user {0}', [_vm.user.email]),"submit-success-message":_vm.$t('Virtual key topped up successfully!'),"submit-btn-text":_vm.$t('Top up'),"submit-promise-callback":_vm.topUpVK,"fields":_vm.fields},scopedSlots:_vm._u([{key:"field.amount",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('price-input',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors},model:{value:(_vm.obj.amount),callback:function ($$v) {_vm.$set(_vm.obj, "amount", $$v)},expression:"obj.amount"}})]}},{key:"field.reason",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.reasonTypes},model:{value:(_vm.obj.reason),callback:function ($$v) {_vm.$set(_vm.obj, "reason", $$v)},expression:"obj.reason"}})]}},{key:"field.giveAutoGift",fn:function(){return [_c('v-checkbox',{staticClass:"mt-n4",attrs:{"disabled":_vm.giveGiftCheckboxDisabled,"label":_vm.$t('Top up also {0} as gift', [_vm.formatMoney(_vm.giftAmount)])},model:{value:(_vm.obj.giveAutoGift),callback:function ($$v) {_vm.$set(_vm.obj, "giveAutoGift", $$v)},expression:"obj.giveAutoGift"}})]},proxy:true}],null,false,1775705689),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }