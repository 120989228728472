<template>
<div id="active-devices" class="ma-6">
  <v-row class="justify-center mt-2 mb-4">
    <span class="display-3">
      Users wallet
    </span>
  </v-row>

  <v-card outlined>
    <div class="ma-5 text-center" style="font-size: 1.2rem;">
      Totale saldi utenti: <b>{{ formatMoney(totalBalances) }}</b>
    </div>

    <v-data-table
        :headers="headers"
        :items="virtualKeys"
        :options.sync="options"
        :footer-props="footerProps"
        :server-items-length="itemsCount"
        :loading="loading"
        item-key="userId"
    >
      <template v-slot:top>
        <div class="d-flex align-center px-8">
          <v-text-field
              class="mr-6 mt-6"
              v-model="query.q"
              label="Filtra utenti nella tabella (nome, email, id...)"
              messages="Press enter to search"
              @keydown.enter="getUsersWallet"
          ></v-text-field>
        </div>
      </template>

      <template v-slot:item.user.email="{ item, value }">
        <v-chip
            outlined
            @click="openTransactions(item)"
        >
          {{ value }}
        </v-chip>
      </template>

      <template v-slot:item.ftClient="{ value }">
        <v-chip v-if="value" link outlined :to="'/ft-clients/' + value.id">{{ value.displayName }}</v-chip>
      </template>

      <template v-slot:item.balance="{ item }">
        {{ formatMoney(item.balance) }}
      </template>
      <template v-slot:item.lastTimeUsed="{ item }">
        {{ formatDateNoSecs(item.lastTimeUsed) }}
      </template>

      <template v-slot:item.groups="{ value }">
        <div>
          <v-chip
              v-for="group in value"
              :key="group.id"
              outlined
              small
              :to="`/groups-management/${group.id}`"
              class="mr-2"
              :disabled="group.loading"
          >
            {{ group.name }}
          </v-chip>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn small icon color="primary"
                     v-bind="attrs"
                     v-on="on"
                     @click="topUp(item)"
              >
                <v-icon>
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Ricarica utente</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn small icon color="primary"
                     v-bind="attrs"
                     v-on="on"
                     class="mx-1"
                     @click="reverseTopUp(item)"
              >
                <v-icon>
                  fa-minus
                </v-icon>
              </v-btn>
            </template>
            <span>Correggi ricarica (rimuovi credito)</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn small icon color="primary"
                     v-bind="attrs"
                     v-on="on"
                     class="mx-1"
                     @click="charge(item)"
              >
                <v-icon>
                  fa-cart-shopping
                </v-icon>
              </v-btn>
            </template>
            <span>Create purchase (admin only)</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <top-up-virtual-key-modal
        :ft-client-id="selectedFtClientId"
        :user.sync="userTopUpModal"
        @close="reload"
    />
    <charge-virtual-key-modal
        v-if="$store.getters.isAdmin"
        :ft-client-id="selectedFtClientId"
        :user.sync="userChargeModal"
        @close="reload"
    />
    <revert-top-up-virtual-key-modal
        :ft-client-id="selectedFtClientId"
        :user.sync="userReverseTopUpModal"
        @close="reload"
    />
  </v-card>
</div>
</template>

<script>
import ChargeVirtualKeyModal from "@/components/modals/administration/ChargeVirtualKeyModal.vue";
import RevertTopUpVirtualKeyModal from "@/components/modals/commons/RevertTopUpVirtualKeyModal.vue";
import TopUpVirtualKeyModal from "@/components/modals/commons/TopUpVirtualKeyModal.vue";
import queryAutoUpdate from "@/mixins/queryAutoUpdate";

export default {
  name: 'UsersWallet',
  components: { TopUpVirtualKeyModal, RevertTopUpVirtualKeyModal, ChargeVirtualKeyModal },
  mixins: [ queryAutoUpdate ],
  data () {
    return {
      query: {
        q: '',
        ipp: null, // ipp = itemsPerPage
      },
      options: {
        itemsPerPage: 10,
        sortBy: ['email'],
        sortDesc: [false],
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100],
      },
      selectedFtClientId: null,
      loading: true,
      virtualKeys: [],
      itemsCount: 0,
      totalBalances: 0,
      userTopUpModal: null,
      userChargeModal: null,
      userReverseTopUpModal: null,
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'User id',
          align: 'start',
          value: 'user.id',
          hide: !this.$store.getters.isAdmin
        },
        {
          text: 'Email',
          align: 'start',
          value: 'user.email'
        },
        {
          text: this.$t('Name'),
          align: 'start',
          value: 'name'
        },
        {
          text: 'Client',
          align: 'start',
          value: 'ftClient'
        },
        {
          text: this.$t('Balance'),
          align: 'start',
          value: 'balance',
          filterable: false
        },
        {
          text: this.$t('Last time used'),
          align: 'start',
          value: 'lastTimeUsed',
          filterable: false
        },
        {
          text: 'Gruppi',
          align: 'start',
          value: 'groups'
        },
        {
          text: 'Actions',
          align: 'start',
          value: 'actions',
          sortable: false,
          filterable: false
        }
      ].filter(header => !header.hide)
    }
  },
  watch: {
    options: {
      handler () {
        this.getUsersWallet()
        this.query.ipp = this.options.itemsPerPage
      },
      deep: true,
    },
  },
  mounted () {
    // load query from url
    this.options.itemsPerPage = this.query.ipp || this.options.itemsPerPage
    this.getOverview() // getUsersWallet is called by data table on watch options after mounted
  },
  methods: {
    reload () {
      setTimeout(this.init, 1000)
    },
    init () {
      this.getOverview()
      this.getUsersWallet()
    },
    async getOverview () {
      try {
        console.log('getOverview')
        const response = await this.$http.payment('virtual-keys/admin/overview')
        this.itemsCount = response.data.count
        this.totalBalances = response.data.totalBalances
      } catch (e) {
      }
      this.loading = false
    },
    async getUsersWallet () {
      this.loading = true
      try {
        const response = await this.$http.payment('virtual-keys/admin', {
          params: {
            q: this.query.q,
            page: this.options.page,
            size: this.options.itemsPerPage,
          }
        })
        response.data.forEach(u => {
          u.name = u.user.surname + ' ' + u.user.name
          return u
        })
        this.virtualKeys = response.data
      } catch (e) {
      }
      this.loading = false
    },

    openTransactions (row) {
      this.$router.push(`/users-wallet/${row.ftClient.id}/transactions/${row.user.id}`)
    },
    topUp (item) {
      this.userTopUpModal = item.user
      this.selectedFtClientId = item.ftClient.id
    },
    reverseTopUp (item) {
      this.userReverseTopUpModal = item.user // be careful, .user.user
      this.selectedFtClientId = item.ftClient.id
    },
    charge (item) {
      this.userChargeModal = item.user
      this.selectedFtClientId = item.ftClient.id
    },
  }
}
</script>

<style scoped>

</style>
