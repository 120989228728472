<template>
  <f-nav-bar
      :current-lang="$i18n.locale"
      :is-authenticated="$store.getters.isAuthenticated"
      registration-complete
      :operations="operations"
      :user-info="userInfo"
      @changeLang="changeLang"
      @returnToAdminDashboard="returnToAdminDashboard"
      @logoutUser="logoutUser"
  />
</template>

<script>

import FNavBar from '@/components/NavBar/FNavBar/FNavBar'

export default {
  name: 'NavBar',
  components: { FNavBar },
  computed: {
    operations () {
      const { isAdmin, isClient, isClientCustomer, isClientAccountant, featureFlags, ccRegComplete } = this.$store.getters

      const adminOperations = [
        () => this.$t('Accounts'),
        { name: () => { return this.$t('Administrators') }, icon: 'fa-users-cog', path: '/administrators' },
        { name: () => { return this.$t('FoodyTek Clients') }, icon: 'fa-users', path: '/ft-clients' },

        undefined,
        () => this.$t('Devices and updates'),

        { name: () => { return this.$t('Devices management') }, icon: 'fa-microchip', path: '/devices' },
        // { name: () => { return this.$t('Devices not assigned') }, icon: 'fa-warehouse', path: '/devices/unassigned' },
        { name: () => { return this.$t('Devices updates') }, icon: 'fa-cloud-upload-alt', path: '/device-updates' },

        undefined,
        () => this.$t('Users'),

        { name: () => { return this.$t("Users virtual keys") }, icon: 'fa-wallet', path: '/users-wallet' },

        undefined,
        () => this.$t('Utilities'),

        { name: () => { return this.$t('QR generator') }, icon: 'fa-qrcode', path: '/qr-code-generator' },

      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      const clientAccountantOperations = [
        () => this.$t('Accountability'),
        { name: () => { return this.$t('Monthly reports') }, path: '/monthly-reports', icon: 'fa-file-invoice-dollar' },
      ]

      const clientOperations = [
        { name: () => { return 'Test local page' }, path: '/test-local-page', icon: 'fa-tools', hide: !this.$env.isLocalhost },

        () => this.$t('Statistics'),
        { name: () => { return 'Dashboard' }, path: '/dashboard', icon: 'fa-chart-line' },
        { name: () => { return this.$t('Per user statistics') }, path: '/dashboard/per-user', icon: 'fa-chart-column' },

        undefined,
        () => this.$t('Account'),
        { name: () => { return this.$t('My account') }, path: '/my-account', icon: 'fa-user-cog' },
        { name: () => { return this.$t('Organisation users') }, path: '/client-users', icon: 'fa-user-tie', newBadge: true },
        { name: () => { return this.$t(`Customers`) }, path: '/users/client-customers', icon: 'fa-building' },

        undefined,
        () => this.$t('Users'),

        { name: () => { return this.$t("User's virtual keys") }, path: '/virtual-keys', icon: 'fa-wallet' },
        { name: () => { return this.$t('Groups management') }, path: '/groups-management', icon: 'fa-user-group' },

        undefined,
        () => this.$t('Accountability'),
        { name: () => { return this.$t('Monthly reports') }, path: '/monthly-reports', icon: 'fa-file-invoice-dollar', newBadge: true },
        { name: () => { return 'Corrispettivi AE' }, path: '/corrispettivi', icon: 'fa-sack-dollar' },
        { name: () => { return this.$t('Remote top ups') }, path: '/remote-top-ups', icon: 'fa-house-laptop' },
        { name: () => { return this.$t('Top ups and gifts') }, path: '/top-up-and-gift', icon: 'fa-gift' },

        undefined,
        () => this.$t('Devices'),

        { name: () => { return this.$t('All devices') }, path: '/devices', icon: 'fa-microchip' },
        { name: () => { return this.$t('Partners, locations and devices') }, path: '/partners', icon: 'fa-industry' },
        { name: () => { return this.$t('Inactive devices') }, path: '/devices/not-active', icon: 'fa-store-slash' },
        { name: () => { return this.$t('Devices transactions') }, path: '/devices/transactions', icon: 'fa-exchange-alt' },

      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      const clientCustomerOperations = [
        () => this.$t('Your account'),
        { name: () => { return this.$t('Account information') }, path: '/client-customers/info', icon: 'fa-user-cog', disabled: !ccRegComplete },
        { name: () => { return this.$t('Remote top up') }, path: '/client-customers/remote-top-up', icon: 'fa-wallet', disabled: !ccRegComplete },
        { name: () => { return this.$t('Past top ups') }, path: '/client-customers/past-top-ups', icon: 'fa-clock-rotate-left', disabled: !ccRegComplete },
      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      if (isAdmin) return adminOperations
      else if (isClient) return clientOperations
      else if (isClientAccountant) return clientAccountantOperations
      else if (isClientCustomer) return clientCustomerOperations
      else return []

    },
    userInfo () {
      return {
        name: this.$store.getters.name,
        surname: this.$store.getters.surname,
        email: this.$store.getters.email
      }
    }
  },
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
      this.$store.commit('changeLang', lang)
    },
    logoutUser () {
      this.$store.commit('logoutUser')
    },
    returnToAdminDashboard () {
      this.$store.dispatch('returnToAdminDashboard')
    }
  }
}
</script>
