var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters.isAdminLoggedAsClient)?_c('div',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Escludi...")]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Escludi statistiche")]),_c('v-card-text',[_c('div',{staticClass:"mb-4"},[_vm._v(" Questa finestra di dialogo serve per escludere determinati periodi di transazioni. È possibile compilare solo uno dei due campi per escludere fino a una certa data (campo “Fine”) o da una certa data (campo “Inizio”). Tuttavia, è anche possibile specificare un intervallo di tempo compilando entrambi i campi. ")]),_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Inizio","readonly":"","append-icon":"fa-xmark"},on:{"click:append":function($event){return _vm.clearField('from')}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}],null,false,2373234306),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":_vm.openFromTimePicker},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveFromDate}},[_vm._v("OK")])],1)],1),_c('v-menu',{ref:"timeMenu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.fromDate),expression:"fromDate"}],attrs:{"label":"Ora Inizio","readonly":"","append-icon":"fa-xmark"},on:{"click:append":function($event){return _vm.clearField('fromTime')}},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},'v-text-field',attrs,false),on))]}}],null,false,4294251427),model:{value:(_vm.timeMenu1),callback:function ($$v) {_vm.timeMenu1=$$v},expression:"timeMenu1"}},[_c('v-time-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeMenu1 = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveFromTime}},[_vm._v("OK")])],1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setCurrentDateTime('from')}}},[_vm._v("Imposta ora attuale")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Fine","readonly":"","append-icon":"fa-xmark"},on:{"click:append":function($event){return _vm.clearField('to')}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}],null,false,3048279060),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":_vm.openToTimePicker},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveToDate}},[_vm._v("OK")])],1)],1),_c('v-menu',{ref:"timeMenu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.toDate),expression:"toDate"}],attrs:{"label":"Ora Fine","readonly":"","append-icon":"fa-xmark"},on:{"click:append":function($event){return _vm.clearField('toTime')}},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},'v-text-field',attrs,false),on))]}}],null,false,1911898741),model:{value:(_vm.timeMenu2),callback:function ($$v) {_vm.timeMenu2=$$v},expression:"timeMenu2"}},[_c('v-time-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeMenu2 = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveToTime}},[_vm._v("OK")])],1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setCurrentDateTime('to')}}},[_vm._v("Imposta ora attuale")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Escludi")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }