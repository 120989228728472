<template>
  <div>
    <v-btn
        color="success"
        @click="modal = true"
    >
      {{this.$t('Create')}}
    </v-btn>
    <action-modal
        v-model="modal"
        :data="data"
        :edit-mode="false"
        submit-btn-text="Aggiungi"
        :cancel-btn-text="$t('Cancel')"
        :loading-text="$t('Loading...')"

        submit-title="Crea nuova chiavetta virtuale tramite email utente"
        submit-success-message="Chiavetta virtuale creata"

        :fields="fields"
        :field-default-options="fieldDefaultOptions"

        :submit-promise-callback="create"
        @close="close"
    />
  </div>
</template>

<script>
import {ActionModal} from '@simple-it/shared-vuetify'

export default {
  name: 'EmailCreateVKModal',
  components: { ActionModal },
  props: {
    ftClientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      data: {
        email: ''
      },
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'email', label: 'Email', rules: 'required' }
      ]
    }
  },
  methods: {
    create () {
      return this.$http.payment.post(
          `/virtual-keys/admin/${this.ftClientId}`,
          this.data
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
