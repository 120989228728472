<template>
  <div>
    <div class="text-h4">
      Dispositivi non attivi
    </div>

    <div class="my-3 text-caption">
      Nota bene: i dati mostrati sono quelli dell'ultima attivazione del dispositivo effettuata,
      pertanto i dispositivi mai attivati non presentano alcun dato
    </div>

    <v-card outlined>
      <v-data-table
          hide-default-footer
          :items="devices"
          :headers="headers"
          item-key="serialNumber"
      >
        <template v-slot:item.partner="{ value }">
          <v-chip link outlined :to="`/partners/${value.id}`">{{ value.name }}</v-chip>
        </template>
        <template v-slot:item.location="{ value }">
          <v-chip link outlined :to="`/locations/${value.id}`">{{ value.name }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DevicesStock',
  data () {
    return {
      devices: []
    }
  },
  computed: {
    headers () {
      return [
        { value: 'serialNumber', text: this.$t('Serial number') },
        { value: 'name', text: 'Nome' },
        { value: 'description', text: 'Descrizione' },
        { value: 'partner', text: 'Partner' },
        { value: 'location', text: 'Sede' }
      ]
    }
  },
  mounted () {
    this.getNonActiveDevices()
  },
  methods: {
    async getNonActiveDevices () {
      try {
        const response = await this.$http.device('devices/not-active')
        this.devices = response.data
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
