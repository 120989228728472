var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-6",attrs:{"id":"active-devices"}},[_c('v-row',{staticClass:"justify-center mt-2 mb-4"},[_c('span',{staticClass:"display-3"},[_vm._v(" Users wallet ")])]),_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"ma-5 text-center",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" Totale saldi utenti: "),_c('b',[_vm._v(_vm._s(_vm.formatMoney(_vm.totalBalances)))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.virtualKeys,"options":_vm.options,"footer-props":_vm.footerProps,"server-items-length":_vm.itemsCount,"loading":_vm.loading,"item-key":"userId"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center px-8"},[_c('v-text-field',{staticClass:"mr-6 mt-6",attrs:{"label":"Filtra utenti nella tabella (nome, email, id...)","messages":"Press enter to search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getUsersWallet.apply(null, arguments)}},model:{value:(_vm.query.q),callback:function ($$v) {_vm.$set(_vm.query, "q", $$v)},expression:"query.q"}})],1)]},proxy:true},{key:"item.user.email",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openTransactions(item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.ftClient",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"link":"","outlined":"","to":'/ft-clients/' + value.id}},[_vm._v(_vm._s(value.displayName))]):_vm._e()]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.balance))+" ")]}},{key:"item.lastTimeUsed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.lastTimeUsed))+" ")]}},{key:"item.groups",fn:function(ref){
var value = ref.value;
return [_c('div',_vm._l((value),function(group){return _c('v-chip',{key:group.id,staticClass:"mr-2",attrs:{"outlined":"","small":"","to":("/groups-management/" + (group.id)),"disabled":group.loading}},[_vm._v(" "+_vm._s(group.name)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.topUp(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ricarica utente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.reverseTopUp(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Correggi ricarica (rimuovi credito)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.charge(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-cart-shopping ")])],1)]}}],null,true)},[_c('span',[_vm._v("Create purchase (admin only)")])])],1)]}}])}),_c('top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.selectedFtClientId,"user":_vm.userTopUpModal},on:{"update:user":function($event){_vm.userTopUpModal=$event},"close":_vm.reload}}),(_vm.$store.getters.isAdmin)?_c('charge-virtual-key-modal',{attrs:{"ft-client-id":_vm.selectedFtClientId,"user":_vm.userChargeModal},on:{"update:user":function($event){_vm.userChargeModal=$event},"close":_vm.reload}}):_vm._e(),_c('revert-top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.selectedFtClientId,"user":_vm.userReverseTopUpModal},on:{"update:user":function($event){_vm.userReverseTopUpModal=$event},"close":_vm.reload}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }