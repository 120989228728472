<template src="./FoodyTekClients.html"></template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'
import InviteUserToPlatformModal from "@/components/modals/commons/InviteUserToPlatformModal.vue";

export default {
  name: 'FoodyTekClients',
  components: { InviteUserToPlatformModal, ActionModal },
  data () {
    return {
      registrationLinkModal: false,
      search: '',
      ftClients: [],

      registrationTokens: [],
      pendingUsers: []
    }
  },
  computed: {
    registrationTokensTableFields () {
      return [
        { value: 'email', text: this.$t('Email') },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    },
    tableFields () {
      return [
        { value: 'displayName', text: 'Display name' },
        { value: 'businessName', text: 'Business name' },
        { value: 'admins', text: 'Administrators' },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    },
    pendingRegistrationTableFields () {
      return [
        { value: 'email', text: this.$t('Email') },
        { value: 'name', text: this.$t('Name') },
        { value: 'surname', text: this.$t('Surname') },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    findSubElement(el, tagName) {
      if (el.tagName === tagName) {
        return el
      }
      for (const child of el.childNodes) {
        const el = this.findSubElement(child, tagName)
        if (el !== null) return el
      }
      return null
    },
    handleEnterKeyDown () {
      const el = this.findSubElement(this.$refs.dataTable.$el, 'A')
      el.click()
    },
    init () {
      this.getRegistrationTokens()
      this.getPendingFTClients()
      this.getFTClients()
    },
    reload () {
      setTimeout(this.init, 600)
    },
    async getPendingFTClients () {
      try {
        const response = await this.$http.auth.get('queries/users/pending-ft-clients')
        this.pendingUsers = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('invitations/ft-client/pending')
        this.registrationTokens = response.data
      } catch (e) {
      }
    },
    async getFTClients () {
      try {
        const response = await this.$http.auth.get('ft-clients')
        response.data.forEach(c => {
          c.admins = c.users.map(u => `${u.name} ${u.surname} (${u.email})`).join(', ')
        })
        this.ftClients = response.data
      } catch (e) {
      }
    },
    async deleteClientAskModal (item) {
      try {
        await this.$dialog.confirmation.deletion(`Testing only operation!<br>Do you really wants to DEFINITELY delete the supplier <b>"${item.businessName}"</b>? <br>` +
            'Be careful, this operation will delete ALL customers, locations, devices and products of this supplier!')

        await this.$dialog.confirmation.deletion('This operation is irreversible, please confirm again or cancel the operation!')

        await this.$http.auth.delete('commands/foodytek-clients/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    async downgradeUser (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('Do you really want to downgrade the user <b>{email}</b>?', { email: item.email }), this.$t('Downgrade'))
        await this.$http.auth.delete(`users/${item.id}/downgrade`)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('Do you really want to revoke the registration token sent to the email <b>{email}</b>?', { email: item.email }), this.$t('Revoke'))
        await this.$http.auth.delete('invitations/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
