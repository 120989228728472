<template>
  <action-modal
      v-model="show"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"
      submit-title="Assign device"
      submit-success-message="Device assigned!"
      submit-btn-text="Assign"
      :submit-promise-callback="assignDeviceCallback"
      @close="closeAssignDevice"
  >
    <v-row class="text-left">
      <v-col cols="12">
        <span class="text-body-1">{{ $t('Serial number') }}</span>
        <span class="ml-4 font-weight-bold">{{ serialNumber }}</span>
      </v-col>
      <text-field
          cols="12"
          rules="required"
          label="Client"
          #default="{ errors, label }"
      >
        <v-autocomplete
            :label="label"
            outlined
            v-model="foodytekClientId"
            :items="ftClients"
            :error-messages="errors"
            item-text="displayName"
            item-value="id"
        />
      </text-field>
    </v-row>
  </action-modal>
</template>

<script>
import { ActionModal, TextField } from '@simple-it/shared-vuetify'

export default {
  name: 'AssignDeviceModal',
  components: { ActionModal, TextField },
  props: {
    serialNumber: String
  },
  data () {
    return {
      foodytekClientId: null,
      ftClients: []
    }
  },
  computed: {
    serialNumberValue: {
      get () {
        return this.serialNumber
      },
      set (newValue) {
        this.$emit('update:serialNumber', newValue)
      }
    },
    show: {
      get () {
        return this.serialNumber != null
      },
      set () {
        this.serialNumberValue = null
      }
    }
  },
  watch: {
    serialNumber (newVal, oldVal) {
      if (!oldVal && newVal) { // If was false and the new is true, open
        this.openModalCallback()
      }

      if (oldVal && !newVal) { this.closeAssignDevice() }
    }
  },
  methods: {
    openModalCallback () {
      this.foodytekClientId = null
      this.getClients()
    },
    assignDeviceCallback () {
      return this.$http.device.patch(`devices/${this.serialNumber}/assign/${this.foodytekClientId}`)
    },
    closeAssignDevice () {
      this.$emit('close')
    },
    async getClients () {
      const res = await this.$http.auth('ft-clients/lite')
      this.ftClients = res.data
    }
  }
}
</script>
