<template>
  <action-modal
      v-model="modalVisible"
      :data="obj"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"
      :submit-title="$t('Invite new user to the platform')"
      :submit-success-message="$t('Invite email sent successfully!')"
      :submit-btn-text="$t('Send link')"
      :fields="fields"
      :submit-promise-callback="submit"
      @close="closeModal"
  >
    <template #field.role="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.role"
          :items="roleTypes"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"/>
            <v-list-item-subtitle v-text="item.description"/>
          </v-list-item-content>
        </template>
      </v-select>
    </template>
  </action-modal>
</template>

<script>

import {ActionModal} from '@simple-it/shared-vuetify'

export default {
  name: 'InviteUserToPlatformModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    specificRole: String,
    defaultRole: String
  },
  data () {
    return {
      obj: {
        email: null,
        role: this.defaultRole ?? null
      },
      fields: this.specificRole ? [ {
        label: 'Email',
        value: 'email',
        rules: 'required|email',
        cols: '12'
      } ]
          // else
          : [ {
        label: 'Email',
        value: 'email',
        rules: 'required|email',
        cols: '12'
      }, {
        label: this.$t('Role'),
        value: 'role',
        rules: 'required',
        cols: '12'
      } ],
      roleTypes: [
        {
          text: this.$t('Administrator'),
          value: 'foodytek-client-admin',
          description: this.$t('Has complete access to the organisation, included this dashboard interface and the applications (Corporation and Business).')
        },
        {
          text: this.$t('Installer'),
          value: 'foodytek-client-installer',
          description: this.$t('Has access only to the applications (Corporation and Business).')
        },
        {
          text: this.$t('Accountant'),
          value: 'foodytek-client-accountant',
          description: this.$t('Has access only to reporting features available on this dashboard (monthly transaction reports, invoices, and other reports).')
        },
      ]
    }
  },
  watch: {
    defaultRole (newValue) {
      if (newValue) {
        this.obj.role = newValue
      }
    }
  },
  computed: {
    modalVisible: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    submit () {
      const role = this.specificRole ?? this.obj.role
      return this.$http.auth.post(
          'invitations/invite/' + role,
          { email: this.obj.email }
      ).then((response) => {
        const token = response.data
        this.showDialogToCopyLink(token)
        this.closeModal()
      })
    },
    async showDialogToCopyLink (token) {
      const baseUrl = this.$env.isLocalhost
          ? 'http://localhost:8080'
          : 'https://login' + (this.$env.isDev ? '.dev' : '') + '.foodytek.com'
      const url = baseUrl + '/register/' + token

      const that = this
      await this.$dialog.confirm({
        title: this.$t('Registration link'),
        text: this.$t('An email with the registration link has been sent. You can also copy this link here and send it manually via other means.')
            + '<br><br>',
        icon: false,
        actions: [
          {
            text: this.$t('Cancel'),
          },
          {
            text: this.$t('Copy link'),
            icon: 'fa-copy',
            color: 'primary',
            outlined: true,
            handler () {
              that.copyTextToClipboard(url)
              that.$dialog.notify.success(this.$t('Link copied to clipboard. You can now paste it where you want.'))
            }
          }
        ]
      })
    },
    closeModal () {
      this.createModal = false
      this.$emit('close')
    }
  }
}
</script>
