export default {
  methods: {
    getReasonCode (reasonId) {
      const reasons = [
        'No error',
        'Vend timed out',
        'Vend failed',
      ]

      reasons[100] = 'Manual refund'

      return reasons[reasonId]
    },
    getTransTypeMsgByCode (transType) {
      const types = {
        PURCHASE_VK: 'Acquisto App (VK)',
        PURCHASE_REFUND: 'Rimborso acquisto',
        TOP_UP_CASH: 'Ricarica cash',
        TOP_UP_CLIENT_CUSTOMER: 'Ricarica cliente',
        TOP_UP_PM: 'Ricarica carta',
        TOP_UP_CARD: 'Ricarica carta',
        TOP_UP_DASHBOARD: 'Ricarica cash dashboard',
        TOP_UP_CASH_DASHBOARD: 'Ricarica cash dashboard',
        TOP_UP_CARD_DASHBOARD: 'Ricarica carta dashboard',
        TOP_UP_GIFT: 'Ricarica regalo',
        REVERSE_TOP_UP_CASH: 'Correzione ricarica cash',
        REVERSE_TOP_UP_CARD: 'Correzione ricarica carta',
        REVERSE_TOP_UP_GIFT: 'Correzione ricarica regalo',
      }

      return types[transType]
    },
  }
}
