<template>
  <div id="devicesStock">
    <!--CREATED DEVICES TABLE-->
    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        {{ $t('Devices not assigned') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
            :items="devices"
            :headers="createdDevicesTableFields"
            item-key="item.id"
        >
          <template v-slot:item.creationDate="{ value }">
            {{ formatDateTime(value) }}
          </template>
          <template v-slot:item.crud="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"
                       @click="openQRCode(item.macAddress)"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>
                    fa-qrcode
                  </v-icon>
                </v-btn>
              </template>
              <span>Download QR-Code</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"
                       @click="copyNFCUrl(item.macAddress)"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>
                    fa-brands fa-nfc-symbol
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy NFC Tag URL</span>
            </v-tooltip>

            <v-btn
                icon
                color="green"
                @click="openAssignDeviceModal(item)"
                class="pa-5"
            >
              <v-icon small>fa-user-check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <assign-device-modal
        :serial-number.sync="assignDeviceSerialNumber"
        @close="closeAssignDevice"
    />
    <device-q-r-code-modal v-model="qrCode"/>
  </div>
</template>

<script>
import AssignDeviceModal from '@/components/modals/administration/AssignDeviceModal'
import DeviceQRCodeModal from '@/components/modals/commons/DeviceQRCodeModal.vue'

export default {
  name: 'DevicesStock',
  components: { AssignDeviceModal, DeviceQRCodeModal },
  data () {
    return {
      devices: [],

      qrCode: {
        show: false,
        macAddress: null
      },
      assignDeviceSerialNumber: null
    }
  },
  computed: {
    createdDevicesTableFields () {
      return [
        { value: 'serialNumber', text: this.$t('Serial number') },
        { value: 'macAddress', text: this.$t('MAC address') },
        { value: 'creationDate', text: this.$t('Creation date') },
        { value: 'crud', text: this.$t('Operations'), align: 'center' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getCreatedDevices()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getCreatedDevices () {
      try {
        const response = await this.$http.device('queries/devices/created')
        this.devices = response.data
      } catch (e) {
      }
    },

    openQRCode (mac) {
      this.qrCode.macAddress = mac
      this.qrCode.show = true
    },
    copyNFCUrl (mac) {
      const url = 'foodytek:device=foodytek-' + mac
      navigator.clipboard.writeText(url)
      this.snackbar = true
    },

    openAssignDeviceModal (device) {
      this.assignDeviceSerialNumber = device.serialNumber
    },
    closeAssignDevice () {
      this.reload()
    },
    printDeviceData () {
      // TODO
    },
    async shipDevice (device) {
      const value = await this.$dialog.warning({
        title: this.$t('Ship device'),
        text: `${this.$t('Do you want to set to "shipped" the device')} <b>${device.serialNumber}</b>?`,
        icon: false
      })
      if (!value) return

      await this.$http.device('commands/devices/ship/' + device.serialNumber)
      this.reload()
      await this.$dialog.notify.success(this.$t('Device set to shipped!').toString())
    }
  }
}
</script>
