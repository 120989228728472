import {loadStripe} from '@stripe/stripe-js';

const aleLocalTestsKey = 'pk_test_51Pyazs4fnv0LeBAz43HuY8vLZyc8LIAzorTuuQUqOD8EyYEPwQGOvxcxEUIcv3Hdhi1vzW8j3FayqyEw9lORPQFn009i4d0WId'

export default {
  data () {
    return {
      stripe: null
    }
  },
  methods: {
    async loadStripe () {
      this.stripe = await loadStripe(aleLocalTestsKey)
    }
  },
}
