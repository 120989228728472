<template>
  <action-modal
      v-if="show"
      v-model="show"
      :data="obj"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Updating balance...')"
      :submit-title="$t('Correct top up for {0}', [user.email])"
      :submit-success-message="$t('Credit removed from balance successfully!')"
      :submit-btn-text="$t('Remove credit')"
      :submit-promise-callback="revertTopUp"
      :fields="[{
          label: $t('Amount'),
          value: 'amount',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      },
      {
          label: $t('Channel'),
          value: 'type',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      }]"
  >
    <template #field.amount="{ errors, label, classes }">
      <price-input
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.amount"
      />
    </template>
    <template #field.type="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.type"
          :items="revertTypes"
      />
    </template>
  </action-modal>
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'
import PriceInput from "@/components/fields/PriceInput.vue";

export default {
  name: 'RevertTopUpVirtualKeyModal',
  components: { PriceInput, ActionModal },
  props: {
    ftClientId: String,
    user: Object
  },
  data () {
    return {
      revertTypes: [
        { text: this.$t('Cash'), value: 'CASH' },
        { text: this.$t('Card'), value: 'CARD' },
        { text: this.$t('Gift'), value: 'GIFT' },
      ],
      obj: {
        amount: null,
        type: null,
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    }
  },
  methods: {
    revertTopUp () {
      return this.$http.payment.post(`virtual-keys/admin/${this.ftClientId}/${this.user.id}/top-up/dashboard/revert`, {
        serialNumber: this.obj.serialNumber,
        amount: this.obj.amount,
        type: this.obj.type,
      })
    }
  }
}
</script>
